.container
  background-image url('./images/wave-fading.png')
  background-repeat no-repeat
  :global(.mobile) &
    background-position 100% 100%
    background-size 108% auto
    padding-bottom 10%
  :global(.desktop) &
    background-position 100% 50%
    background-size auto 100%
    display flex
    align-items center
    padding 50px 0 34px

.ears
  background center / auto 100% url('./images/your-ears.png') no-repeat

  :global(.mobile) &
    width 222px
    height 84px
    margin-left 10px
    transform rotate(-9.32deg)
  :global(.desktop) &
    flex 0 0 319px
    height 121px

.buttonWrapper
  :global(.mobile) &
    display flex
    padding 12px 20px 0 0
    text-align right

  :global(.desktop) &
    flex none
    margin 0 4px 0 auto
    justify-self flex-end
    @media (prefers-reduced-motion: no-preference)
      transform scale(1) translateY(0)
      transition transform .4s
      &:hover
        transform scale(1.08) translateY(-3px)

.buttonText
  text-transform uppercase
  font 500 14px/16px
  color #fff
  :global(.desktop)
    font 500 18px/22px
    letter-spacing 0.02em

.card
  display block
  border-radius 16px
  overflow hidden
  :global(.mobile) &
    max-width 550px
    margin 0 auto

.wrapper
  display flex
  :global(.mobile) &
    flex-direction column

.body
  display flex
  flex 1
  flex-direction column
  box-sizing border-box
  max-height 215px
  padding 30px
  :global(.mobile) &
    padding 15px 20px 20px 15px

.date
  display block
  margin-bottom 15px
  :global(.mobile) &
    margin-bottom 5px

.firstAnnounce
  margin-top 10px

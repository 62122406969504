.wrapper
  display flex
  flex-direction row

  ._imgPosition_top &
    flex-direction column

.title
  flex 1 1 auto

  ._imgPosition_top &
    text-align center

.headline
  font-weight 400
  transition color .3s ease-out
  ._size_s &
    font-size 16px
    line-height 19px
  ._size_m &
    font-size 17px
    line-height 20px

.altHeadline
  font-weight 400
  margin-top 5px
  ._size_s &
    font-size 15px
    line-height 19px
  ._size_m &
    font-size 16px
    line-height 20px

.img
  $horIndent = 20px
  flex 0 0 auto
  margin-right $horIndent
  align-self flex-start
  border-radius 4px
  overflow hidden

  ._imgPosition_top &
    align-self center
    margin 0 0 10px 0

  ._imgPosition_right &
    order 2
    margin-left $horIndent
    margin-right 0

.tags
  font-weight 400
  margin-top 5px
  ._size_s &
    font-size 15px
    line-height 19px
  ._size_m &
    font-size 16px
    line-height 20px

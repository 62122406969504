$zIndex1 = 1
$zIndex2 = 2
$zIndex3 = 3
$zIndex4 = 4
$zIndex5 = 5

.marquee
  position relative
  margin 25px 0
  display block

.items
  padding 3px 12px 4px
  position relative
  box-sizing border-box

  :global(.desktop) &
    display grid
    grid-template-columns repeat(5, 1fr)

  :global(.mobile) &
    display block
    white-space nowrap
    width 100%

  &:before
  &:after
    content ''
    position absolute

  &:before
    z-index $zIndex2
    background-image url('./images/back_01.png'), url('./images/back_02.png')
    background-size 116px 44px, 36px 38px
    background-position 30% 3px, 85% 25px
    background-repeat no-repeat
    inset -20px 0
    transition background-position .3s ease-out

    .marquee:hover &
      background-position 30% 0, 85% 27px

  &:after
    z-index $zIndex3
    inset 0
    background radial-gradient(49% 82% at 1% 100%, #F9FC3B 0%, rgba(249, 252, 59, 0) 83%),
      radial-gradient(49% 107% at 95% 3%, #00A7FA 0%, rgba(0, 167, 249, 0) 100%),
      radial-gradient(62% 146% at 3% 6%, #1EF2F8 0%, rgba(25, 243, 249, 0) 60%),
      linear-gradient(#65DF0D, #65DF0D)

  :global(.desktop) &
    &:after
      border-radius 8px

.item
  text-align center
  position relative
  z-index $zIndex5
  font-size 14px
  line-height 21px
  color #fff
  white-space nowrap
  text-overflow ellipsis
  overflow hidden

  :global(.mobile) &
    vertical-align bottom
    display inline-block
    padding 0 10px

  &:before
  &:last-child:after
    content ''
    position absolute
    inset 0
    margin auto 0
    width 3px
    height 3px
    border-radius 50%
    background-color #fff
    transform translateY(1px)

  &:last-child:after
    left auto

.shadow
  position absolute
  inset 0
  opacity 1
  z-index $zIndex1
  box-shadow 0 15px 10px -10px rgba(101,223,13,0.5), 0 15px 10px -10px rgba(249,252,59,0.5)
  transform translateY(0)
  transition opacity .3s, transform .35s

  .marquee:hover &
    opacity 0.8
    transform translateY(-2px)

.under
  z-index $zIndex4
  inset -20px 0
  position absolute
  background-image url('./images/front_01.png'), url('./images/front_02.png')
  background-size 17px 20px, 31px 32px
  background-repeat no-repeat
  background-position 15% 85%, 64% 0
  transition background-position .3s ease-out

  .marquee:hover &
    background-position 15.5% 80%, 64.5% 5%
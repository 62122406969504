.link
  display block
  line-height 12px

.categoryText
  margin-right 20px

.altHeadline
  margin-top 5px

.headline
  margin-top 3px
  &._altHeadline
    font-weight 700 !important
  .link:hover &
    text-decoration underline
    text-underline-offset 2px

.promo
  text-decoration none

  :global(.mobile) &
    display flex
    justify-content center

  :global(.desktop) &
    margin-left 30px

.text
  white-space nowrap
  font-weight 500
  font-size 16px
  line-height 1.5

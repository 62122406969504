.link
  display flex
  flex-direction column
  align-items center

.img
  border-radius 50%
  overflow hidden
  margin-bottom 15px

.headline
  &:not(._isLarge &)
    text-align center

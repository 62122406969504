.lcpFixer

  :global(.desktop) &
    $additionalHeight = 600px
    padding-bottom $additionalHeight
    margin-bottom -($additionalHeight)

    &:after
      content ''
      height 100%
      width 100%
      position absolute
      top 0
      z-index -1

  :global(.mobile) &
    $topShift = 20px
    $additionalHeight = 200px
    padding-top $topShift
    margin-top -($topShift)
    padding-bottom $additionalHeight
    margin-bottom -($additionalHeight)
.authorCard
  margin-bottom 20px

.wrapper
  display flex
  :global(.desktop) &
    justify-content space-between
    align-items center
  :global(.mobile) &
    flex-direction column

.name
  :global(.mobile) &
    order 2

.allAuthorsLink
  text-decoration underline
  font-size 14px
  line-height 21px
  :global(.mobile) &
    display inline-block
    margin-bottom 20px

.job
  margin 30px 0 20px
  :global(.mobile) &
    margin 20px 0

.socialNetworks
  padding 14px 0 24px
  :global(.mobile) &
    padding 14px 0

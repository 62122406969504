.container
  padding 0 20px

  :global(.desktop) &
    position relative
    padding 0 20px 26px

    &:before
      content ''
      position absolute
      inset 0
      top 36px
      border-radius 16px
      background-image linear-gradient(156deg, #D8EDFF 0%, #E1C0FF 100%)

    &:after
      content ''
      position absolute
      inset 0
      background 15% 0 / contain url('./images/man.png') no-repeat

  :global(.mobile) &
    background-image url('./images/man.png'), linear-gradient(156deg, #D8EDFF 0%, #E1C0FF 100%)
    background-position 15% 0, 0 36px
    background-size contain
    background-repeat no-repeat

.content
  margin-left 50%

  :global(.desktop) &
    position relative
    z-index 1

  :global(.mobile) &
    text-align right

.ears
  position relative

  :global(.desktop) &
    height 120px
    background url('./images/ears.png') no-repeat
    background-size contain
    left -80px

  :global(.mobile) &
    height 100px
    background right center url('./images/ears.png') no-repeat
    background-size contain
    left -20px

.button
  display inline-flex
  flex-direction column
  align-items center

  :global(.desktop)
    padding 10px 0 26px

  :global(.mobile) &
    padding 0 0 16px

.prime
  background url('./images/prime.png') no-repeat
  background-size contain

  :global(.desktop) &
    width 124px
    height 26px
    margin 0 auto 22px

  :global(.mobile) &
    width 120px
    height 20px
    margin 0 auto 12px

.buttonLink
  display block

  :global(.desktop) &
    @media (prefers-reduced-motion: no-preference)
      transform scale(1) translateY(0)
      transition transform .4s
      &:hover
        transform scale(1.08) translateY(-3px)

.buttonText
  letter-spacing 0.2px
  text-transform uppercase
  color #fff

  :global(.mobile) &
    font-size 13px
    line-height 16px
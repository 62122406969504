.header
  border-radius 8px
  width 75%
  height 21px
  margin 24px 0
  :global(.mobile) &
    width 100%
    height 42px
    margin 24px auto 20px 

.card
  border-radius 16px
  :global(.desktop) &
    width 235px
    height 272px
    margin-right 20px

  :global(.mobile) &
    width 220px
    height 255px
    margin-right 10px

/**
 * См. ридми компонента Fullwidth
 */
.horScrollFixer
  :global(.desktop) &
    max-width 100%
    overflow hidden
  :global(.mobile) &
    overflow-x hidden

/**
 * PopMechanic - партнерская плашка "Слушать музыку".
 * Определяем стили под плашкой - состояние открыта
 */
:global(.popmechanic-mobile body.mobile)
  background #000 url(site/images/waves-crispy.webp) no-repeat
  background-size 464px 129px
  background-position -47px 0px

  @media screen and (min-width: 321px)
    background-size 145%
    background-position -47px -6px

  @media screen and (min-width: 360px)
    background-position -47px 6px

  @media screen and (min-width: 391px)
    background-position -47px 19px

  @media screen and (min-width: 750px)
    background none

/**
 * PopMechanic.
 * Определяем стили под плашкой - состояние закрыта
 */
:global(.popmechanic-mobile body.mobile:not(:has(>.responsive-wrapper)))
  padding-top 46px

  @media screen and (min-width: 360px)
    padding-top 58px

  @media screen and (min-width: 391px)
    padding-top 70px

.opinion
  position relative
  overflow hidden
  padding-top 12px
  border-radius 16px

.quotes
  position relative
  z-index 1
  display flex
  justify-content center
  transform translateY(12px)

.body
  margin 0

.link
  @media (prefers-reduced-motion: no-preference)
    &:hover
      .authorAnimated
        &:before
          transform translateY(10px)

.authorAnimated
  &:before
    @media (prefers-reduced-motion: no-preference)
      transform translateY(0)
      transition transform .6s

.content
  position relative
  text-align center
  padding 35px 33px 30px 42px

  :global(.mobile) &
    padding 20px
.selectors
  :global(.desktop) &
    display flex

.selectWrapper
  :global(.desktop) &
    display flex
    justify-content space-between
  :global(.mobile) &
    width 100%

.searchSelect
  margin 0 0 10px 0
  :global(.desktop) &
    width 300px
    margin 0 20px 0 0

.buttonWrapper
  text-align end

.buttonContent
  font-weight 400
.container
  position relative
  overflow hidden
  border-radius 16px
  max-width 250px

  &:after
    content ''
    position absolute
    left 0
    right 0
    bottom 0
    top 0
    background-image \
      linear-gradient(transparent 0%, #080f6a 100%),\
      linear-gradient(transparent 30%, #080f6a 91%)

  @media (prefers-reduced-motion: no-preference)
    .image
      transform scale(1)
      transition transform .4s
    &:hover
      .image
        transform scale(1.03)

.content
  display flex
  flex-direction column
  justify-content flex-end
  position absolute
  bottom 0
  z-index 1
  padding 0 20px 20px

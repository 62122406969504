$rounding = 8px
$sberPromoHeight = 34px

.wrapper
  flex none
  position relative
  z-index 1

  &._isInnerPageWithoutTopTopic
    margin-bottom ($sberPromoHeight / 2) - $rounding

.header
  position relative
  max-width 1440px
  margin 0 auto
  display flex
  align-items center
  box-sizing content-box

  .stickyHeader &
    box-sizing border-box

.logoLink
  flex none
  position absolute
  left 50%
  top 50%
  transform translate(-50%, -50%)

.burgerWrapper
  flex none
  margin-left auto
  margin-top -9px

.menuWrapper
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  display flex
  z-index $baseZIndex
  flex-direction column
  opacity 0
  transform translateY(-100%)

  &._isOpened
    opacity 1
    transform translateY(0)

.menuClose
  position absolute
  right 15px
  top 15px
  padding 10px
  color #000

.menuSearch
  margin-top 47px

.content
  $initialPadding = 25px
  $topTopicShift = 50px
  border-radius $rounding $rounding 0 0
  min-height $rounding
  overflow hidden
  box-sizing border-box

  /**
    По неведомым причинам в шапку добавили блок новостей.
    Причем только для главной страницы.
    Благодаря этому мы имеем такие сложные отступы.
   */
  ._hasNews &
    padding-top $initialPadding

  ._hasTopTopic &
    margin-top -($topTopicShift)

  ._isInnerPageWithTopTopic &
    padding-bottom $topTopicShift

  ._hasTopTopic._hasNews &
    padding-top ($initialPadding + $topTopicShift)

.stickyHeader
  position fixed
  left 0
  right 0
  top 0
  max-height 0
  overflow hidden
  background-position 26% 59% !important
  z-index $baseZIndex

  @media (prefers-reduced-motion: no-preference)
    transition max-height .1s linear

  .burgerWrapper
    margin-top -4px

  &._visible
    max-height 70px

.sberPromo
  display flex
  align-items center
  justify-content center
  gap 0 10px
  margin-bottom -17px

  ._hasTopTopic &
    margin-bottom 20px

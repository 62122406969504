.name
  font-size 16px
  line-height 24px
  text-decoration underline
  &:hover
    text-decoration none

.job
  margin-bottom 20px
  font-size 12px
  line-height 18px


.alphabetWrapper
.switchInnerWrapper
  display flex

.alphabetWrapper
  align-items baseline

.switchWrapper
  position absolute
  overflow hidden
  right 40px
  z-index 2
  border-radius 16px
  max-height 31px
  &._isOpened 
    max-height 98px
  @media (prefers-reduced-motion: no-preference)
    transition max-height .2s linear

.switchInnerWrapper
  position relative
  padding 0 5px 10px
  flex-direction column
  @media (prefers-reduced-motion: no-preference)
    transition transform .2s

.charButtonWrapper
  align-self center
  padding 0 !important

.switchButtonWrapper
  margin-right 5px
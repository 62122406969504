.container
  :global(.desktop) &
    position relative
    padding 0 20px 26px

    &:before
      content ''
      position absolute
      inset 0
      top 36px
      border-radius 16px
      background-image linear-gradient(156deg, #D8EDFF 0%, #E1C0FF 100%)

    &:after
      content ''
      position absolute
      inset 0
      bottom 40px
      background 33.3% 0 /contain url('./images/pica.png') no-repeat

  :global(.mobile) &
    padding 20px
    background-image url('./images/pica.png'), linear-gradient(156deg, #D8EDFF 0%, #E1C0FF 100%)
    background-position 10% 0, 0 36px
    background-size contain
    background-repeat no-repeat

.content
  text-align right

  :global(.desktop) &
    position relative
    z-index 1

.button
  display inline-flex

  :global(.desktop) &
    margin 184px 0 0

  :global(.mobile) &
    margin 120px 0 20px

.buttonLink
  display block

  :global(.desktop) &
    @media (prefers-reduced-motion: no-preference)
      transform scale(1) translateY(0)
      transition transform .4s
      &:hover
        transform scale(1.08) translateY(-3px)

.buttonText
  letter-spacing 0.2px
  text-transform uppercase
  color #fff
  font-size 18px
  line-height 21px
  font-weight 500
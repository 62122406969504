/**
 * Площадь lcpFixer должна быть больше обложки топика.
 * Для увеличения его площади без визуального увеличения отступов
 * используем padding в комбинации с отрицательным margin
 * REF: https://web.dev/lighthouse-largest-contentful-paint/
 */
.lcpFixer
  :global(.desktop) &
    $additionalHeight = 600px
    padding-bottom $additionalHeight
    margin-bottom -($additionalHeight)
    &:after
      content ''
      height 100%
      width 100%
      position absolute
      top 0
      z-index -1
  :global(.mobile) &
    $additionalHeight = 200px
    padding-bottom $additionalHeight
    margin-bottom -($additionalHeight)

.author
  display block
  margin-top 20px

.meta
  display flex
  justify-content center

.avatar
  flex 1 1 auto
  display flex
  margin 20px 0 10px
  justify-content center

  :global(.mobile) &
    margin 20px 0 0

  &:before
    content ""
    position absolute
    z-index -1
    left 0
    bottom -130px
    width 100%
    height 120%

    :global(.mobile) &
      transform rotate(-5deg)
      left -10px
      width 120%
      height 140%

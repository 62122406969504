.media
  flex none
  width 320px
  text-align center
  :global(.mobile) &
    width auto

.btnLink
  display inline-block
  margin-top 20px
  transition opacity .2s ease-in-out
  &:hover
    opacity 0.7

.btnWrapper
  display flex
  align-items center
  gap 0 10px

.item
  :global(.mobile) &
    margin-left -10px
  &:not(:last-child)
    margin-bottom 5px

.buttonContent
  text-align left
  border-bottom-width 1px
  border-bottom-style dashed

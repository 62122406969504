.shapka
  display flex
  justify-content space-between
  align-items center
  margin-bottom 30px
  :global(.mobile) &
    flex-direction column
    margin-bottom 20px

.headline
  align-self flex-start

.content
  $radius = 16px
  display flex
  justify-content space-around
  padding 15px 20px 30px 20px
  border-radius $radius
  :global(.mobile) &
    position relative
    border-top-left-radius 0
    border-top-right-radius 0
    flex-direction column-reverse
    padding 0
    &:after
      $height = 100px
      content ''
      display block
      height $height
      position absolute
      top -($height)
      left 0
      right 0
      border-radius $radius $radius 0 0
      z-index -1


.table
  border-collapse collapse
  table-layout fixed
  flex 1 1 auto
  width 100%
  :global(.desktop) &
    width 60%
    margin-right 20px
  td
    :global(.desktop) &
      padding 15px 0
      width 38%
      &.valueWrapper
        width 62%

.rowWrapper
  vertical-align baseline
  &:last-child
    border-bottom none !important

.valueWrapper
  overflow-x hidden

.title
  :global(.mobile) &
    padding-top 15px
    font-size 14px
    line-height 14px

.value
  margin-top 10px
  :global(.mobile) &
    padding-bottom 15px
  :global(.mobile tr:last-child) &
    border-bottom none !important

.play
  margin-left 10px

.discography
  display flex
  align-items center

.zodiac_ru
  text-transform capitalize

.discographyParagraph
.paragraphWithLink
  margin 0 0 2px
  line-height 1.5

.paragraphWithLink
  display flex
  align-items center
  white-space pre-wrap

.linkContent
  display inline-block

.paragraphIconLink
  display inline-flex
  vertical-align sub
  margin-left 10px

$radius = 40px
$borderRed = #f99
$borderGray = #EAEAEA

.reactions
  display flex
  align-items center
  justify-content space-between
  gap 10px

  :global(.mobile) &
    padding-right 20px

.input
  position absolute
  width 0
  height 0
  z-index -1
  margin 0
  opacity 0

  &:hover ~ .content
    &:after
      opacity 1
      box-shadow inset 0 0 0 2px $borderRed

  &:disabled
    ~ .content
      cursor default

    ~ .content:after
      opacity 0

    ~ .content:before
      opacity 1
      box-shadow inset 0 0 0 2px $borderGray

  &:checked
  &:disabled:checked
    ~ .content
      background #FFBFBF

    ~ .content:before
      opacity 0

    ~ .content:after
      opacity 1
      box-shadow inset 0 0 0 2px $borderRed

.content
  display flex
  align-items center
  padding 9px 18px
  border-radius $radius
  background #F0F0F0
  cursor pointer
  transition background .15s
  position relative
  white-space nowrap

  :global(.mobile) &
    padding 9px 16px

  &:before
  &:after
    content ''
    position absolute
    inset 0
    border-radius $radius
    transition opacity .15s ease-in

  &:before
    opacity 1
    box-shadow inset 0 0 0 1px $borderGray

  &:after
    opacity 0

.text
  font-size 14px
  line-height 21px
  margin-left 10px

  :global(.mobile) &
    font-size 12px
    line-height 18px
    margin-left 8px

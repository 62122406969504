.gameCompare
  text-align center
  border-radius 16px
  padding 40px 20px 60px
  background radial-gradient(80% 50% at 0% 0%, #fff 0%, rgba(191,90,170,0) 60%), radial-gradient(80% 50% at 100% 0%, #fff 0%, rgba(191,90,170,0) 60%), linear-gradient(to right,  #a2f2ff, #88f6ff, #faf8ff, #fde5ff, #fcd4fe)
  :global(.mobile) &
    padding 20px 20px 30px

.link
  display block
  margin-bottom 20px
  :global(.mobile) &
    margin-bottom 40px

.vs
  display flex
  justify-content center
  margin-top 55px

.header
  max-width 660px
  margin 0 auto

.title
  margin 0

.subtitle
  margin-top 10px
  color #484848

.wrapper_0
.wrapper_1
  display flex
  border-radius 16px
  overflow hidden

.wrapper_0
  transform rotate(-7deg)

.wrapper_1
  transform rotate(7deg)

.icon
  position relative
  display flex
  flex 0 0 auto
  justify-content center
  align-items center
  width 86px
  height 86px
  border-radius 50%
  margin 70px -40px
  z-index 1
  :global(.mobile) &
    width 60px
    height 60px
    margin 70px -30px

.othersLink
  font-weight 500

.newsFeed
  :global(.desktop) &
    background #F7F7F7
    border-radius 16px
    padding 30px 50px

.title
  text-align center
  margin-bottom 30px
  :global(.mobile) &
    margin-bottom 0

.moreButton
  margin-top 40px
  display flex
  justify-content center
  :global(.mobile) &
    margin-top 10px

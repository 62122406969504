.errorText
  margin-bottom 10px

.errorIconWrapper
.error
  display flex
  justify-content center
  flex 0 0 auto

.error
  width 200px
  height 200px
  align-items center

.errorIcon
  width 149px
  height 172px

$desktopIndent = 24px
$mobileIndent = 15px

:global(.vikont)
  position relative

.embedGradient
  :global(.desktop) &
    margin-top 48px

  :global(.mobile) &
    margin-top 30px

.gradientBackground
  position absolute
  top 0
  left 0
  right 0
  margin-left calc((100vw - 100%) / -2)
  width 100vw
  height 85%
  z-index -1

  ._shiftOnStarPage &
    margin-left calc((100vw - 65%) / -2)

  @media screen and (max-width: 1024px)
    ._shiftOnStarPage &
      margin-left -276px
      min-width 1024px

  &:before
    content ''
    position absolute
    inset 0
    z-index -1

  :global(.desktop) &
    &:before
      margin-top -($desktopIndent)

  :global(.mobile) &
    &:before
      margin-top -($mobileIndent)

  &._gradientBackground_1
    &:before
      background radial-gradient(ellipse at 75% 10%, #7290de 0%, #79b4f2 25%, #dfd4ef 50%, transparent 70%),
        linear-gradient(180deg, #e4c3bc 50%, transparent)

  &._gradientBackground_2
    &:before
      background radial-gradient(circle at -20% -30%, #086e75 0%, transparent 25%),
        radial-gradient(ellipse at -10% -20%, #086e75 0%, transparent 45%),
        radial-gradient(ellipse at 110% 0%, #d7fabe 0%, transparent 40%),
        linear-gradient(180deg, #76b99b 0%, #bee3d4 50%, transparent 100%)

  &._gradientBackground_3
    &:before
      background radial-gradient(ellipse at 0% 0%, #41438c 0%, transparent 40%),
        radial-gradient(ellipse at 100% 0%, #f986fe 0%, transparent 50%),
        linear-gradient(180deg, #9d64c6 0%, #d0b2e7 50%, transparent 100%)

  &._gradientBackground_4
    &:before
      background radial-gradient(ellipse at 0% 0%, #4956c9 0%, transparent 50%),
        radial-gradient(ellipse at 100% 0%, #fe7d3c 0%, transparent 40%),
        linear-gradient(180deg, #a66a80 0%, #e6d3d3 70%, transparent 100%)

  &._gradientBackground_5
    &:before
      background radial-gradient(ellipse at 40% 0%, #ae85d5 0%, transparent 50%),
        radial-gradient(ellipse at 100% 0%, #cb71a6 0%, transparent 40%),
        radial-gradient(ellipse at 100% 40%, #feb0c5 20%, transparent 40%),
        linear-gradient(180deg, #c891e2 0%, #fed6fe 70%, transparent 100%)

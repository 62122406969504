.lcpFixer

  :global(.desktop) &
    $additionalHeight = 600px
    padding-bottom $additionalHeight
    margin-bottom -($additionalHeight)

    &:after
      content ''
      height 100%
      width 100%
      position absolute
      top 0
      z-index -1

  :global(.mobile) &
    $topShift = 20px
    $additionalHeight = 200px
    padding-top $topShift
    margin-top -($topShift)
    padding-bottom $additionalHeight
    margin-bottom -($additionalHeight)

.socializator
  padding 15px

.subscribe
  width 70px
  margin-right 30px

.cover
  aspect-ratio 1.75

  :global(.desktop) &
    border-radius 15px

  :global(.mobile) &
      max-width 600px
      margin 0 auto

.text
  height 300px
  
.card
  display flex
  flex-direction column
  justify-content flex-end
  margin-top -25px

  // &._size_s
  //   margin-top -20px

.wrapper
  position relative
  padding 25px 20px
  box-sizing border-box
  border-radius 12px 12px 0 0
  background linear-gradient(to right, #dabdbe, 40%, #5ab0ff, #679de8)
  @media (prefers-reduced-motion: no-preference)
    transform scale(1) translateY(0)
    transition transform .4s
    &:hover
      transform scale(1.02) translateY(-1px)

  ._size_s &
    min-height 155px
    padding 30px 15px 30px 0
    border-radius 8px

.image
  position absolute
  bottom 0
  ._size_s &
    border-radius 0 0 0 8px
    overflow hidden
    left 0

.imageSmallWrapper
  transform translateX(-50px)

.content
  position relative
  margin-left 230px
  max-width 380px
  z-index 1
  ._size_s &
    max-width none
    margin-left 125px

.none
  position absolute
  right 15px
  bottom 15px

.altHeadline
  margin-top 5px
  -webkit-line-clamp 2
  display -webkit-box
  -webkit-box-orient vertical
  overflow hidden

.btn
  margin-top 10px

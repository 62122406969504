.imageWrapper
  position relative
  z-index 1
  &:before
    content ''
    display block
    position absolute
    left 10%
    width 80%
    height 20px
    bottom 10px
    filter blur(23px)
    background #000

.image
  border-radius 8px
  overflow hidden

.galleryPreview
  display block
  margin 0 auto
  transition opacity .2s ease-in-out
  &:hover
    opacity 0.7

.galleryPreviewItem
  position relative
  border-radius 8px
  overflow hidden
  margin 0 auto
  & > div
    border-radius 0 0 8px 8px

.count
  position absolute
  bottom 18px
  right 16px
  width 50px
  height 26px
  display flex
  justify-content space-around
  align-items center
  border-radius 20px !important
  padding 0 7px
  box-sizing border-box

.socializatorWrapper
  border-top-style solid
  border-top-width 1px
  border-bottom-style solid
  border-bottom-width 1px
  padding 15px 0
  display flex
  font-size 12px
  line-height 15px
  align-items center
  &._disableDividers
    padding 0
    border none !important

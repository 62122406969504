.container
  padding 0 20px
  background-image linear-gradient(236deg, #540569 20%, #781299 35%, #6d38cb 60%, #4044ab 75%, #151517 93%)
  background-repeat no-repeat

  :global(.desktop) &
    border-radius 16px

.button
  padding 60px 0
  text-align center

  :global(button)
    box-shadow 0 12px 24px 0 #14A73E99

  :global(.mobile) &
    padding 50px 0

.buttonLink
  display block

  :global(.desktop) &
    @media (prefers-reduced-motion: no-preference)
      transform scale(1) translateY(0)
      transition transform .4s
      &:hover
        transform scale(1.08) translateY(-3px)

.buttonText
  font-style italic
  font-weight 800
  letter-spacing 2px
  text-transform uppercase
  color #fff
  text-shadow #0000001A 0 2px 4px

  :global(.desktop) &
    font-size 71px
    line-height 85px
    padding 0 70px

  :global(.mobile) &
    font-size 52px
    line-height 65px
    padding 0 24px
.link
  display block

.container
  position relative
  overflow hidden
  border-radius 16px
  max-width 490px
  @media (prefers-reduced-motion: no-preference)
    .image
      transform scale(1)
      transition transform .4s
    &:hover
      .image
        transform scale(1.03)

  &._narrow
    max-width 290px
    border-radius 8px

.altHeadline
  margin-top 5px
  color #fff

.icon
  margin-bottom 5px

.content
  $paddingTop = 10px
  $gradientsEdgePosition = 'calc(100% - 2em - %s)' % ($paddingTop)

  padding $paddingTop 30px 30px
  position absolute
  bottom 0
  left 0
  right 0
  background-image \
    linear-gradient(0deg, #000000 0, #000000 $gradientsEdgePosition, transparent $gradientsEdgePosition),\
    linear-gradient(0deg, #000000 $gradientsEdgePosition, transparent)
  ._narrow &
    padding $paddingTop 20px 20px

.socialMedia
  text-decoration none
  height 100%
  width 100%
  display flex
  align-items center
  box-sizing border-box
  border-radius 16px

  :global(.desktop) &
    padding 10px

  :global(.mobile) &
    border-radius 8px
    flex-direction column
    position relative
    padding 32px 10px 20px

.logo
  z-index 1
  display flex
  align-items center
  margin-right 20px

  :global(.mobile) &
    position absolute
    width 32px
    top -15px
    left calc(50% - 8px)

.subscribe
  font-weight 500
  font-size 14px
  line-height 17px
  display flex
  align-items center
  color #fff
  width 134px
  height 32px
  border-radius 20px
  justify-content center
  :global(.desktop) &
    margin-left auto

.icon
  display flex
  width 20px
  height 20px
  border-radius 10px
  margin-left 8px
  align-items center
  justify-content center
  background #fff

.title
  font-weight 400
  font-size 16px
  line-height 20px
  padding 0
  padding-right 15px
  max-height 100%
  color #000

  :global(.mobile) &
    font-size 14px
    line-height 18px
    margin-bottom 15px


@import './networks/vkontakte'
@import './networks/odnoklassniki'
@import './networks/telegram'
@import './networks/youtube'

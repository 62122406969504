.name
  width 300px

.header
  display flex
  height 38px

  :global(.mobile) &
    height 48px

  :global(.desktop) &
    justify-content space-between

.info
.cover
  border-radius 16px

.cover
  position relative
  max-width 500px
  height 350px
  margin 0 auto -90px

.info
  height 500px

  :global(.mobile) &
    height 750px

.text
  height 300px

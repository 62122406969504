$outerRadius = 16px

.wrapper
  display flex
  height 100%
  box-sizing border-box
  flex-grow 1
  flex-direction column
  border-radius $outerRadius
  overflow hidden
  margin 0 auto
  &._hasBorder
    border-width 1px
    border-style solid
  &._isGallery
    padding 10px 10px 20px
    border none
    background linear-gradient(180deg, #8253aa 0%, #202c80 100%)
  &._minimalistic
    background none
  &._isImageless
    border none
    background linear-gradient(155.86deg, #D8EDFF 0%, #E1C0FF 100%)
  @media (prefers-reduced-motion: no-preference)
    .image
      transform scale(1)
      transition transform .4s
    &:hover
      .image
        transform scale(1.03)

.link
  ._isImageless &
    display flex
    flex-direction column
    justify-content center
    flex-grow 1

.content
  padding 15px 20px 20px
  ._isGallery &
    padding 15px 10px 0
  ._minimalistic &
    padding 10px 10px 0 0
  ._isImageless &
    padding 15px 20px

.headline
  margin 5px 0
  transition color .2s
  font-size 17px
  line-height 20px
  ._isImageless &
    font-size 18px
    line-height 27px
  ._size_s &
    font-size 13px
    line-height 16px

.altHeadline
.tags
  font-size 16px
  line-height 20px
  ._size_s &
    font-size 13px
    line-height 16px

.iconWrapper
  position absolute
  bottom 18px
  right 16px
  ._minimalistic &
    :global(.mobile) &
      @media (max-width: 450px)
        bottom 8px
        right 6px

.imageWrapper
  overflow hidden
  transform translate(0)
  border-radius $outerRadius
  overflow hidden
  ._hasBorder &
    border-radius 0

.images
  position relative
  border-radius 8px
  overflow hidden
  margin 0 auto

.images div
  border-radius 0 0 8px 8px

.tags
  margin-top 5px

.wrapper
  @media (prefers-reduced-motion: no-preference)
    transform scale(1)
    transition transform .4s
    &:hover
      transform scale(1.01)
      &._isClippedImage
        .image
          transform scale(1.03) translateY(-6px)
      .waves
        &:before
          transform rotate(20deg) translateX(50px)
  &._small
    display flex
    justify-content center

.card
  display flex
  position relative
  min-height 320px
  box-sizing border-box
  border-radius 16px
  overflow hidden
  background-image radial-gradient(50% 50% at 90% 0%, #bf5aaa 0%, rgba(191,90,170,0) 60%), linear-gradient(to bottom left, #9bd0fe, #7d51ac)

  ._small &
    max-width 500px
    width 100%
    flex-direction column-reverse
    background linear-gradient(to right, #9bd0fe, #632895)
    border-radius 8px
    min-height 400px
  ._isClippedImage &
    overflow visible
  ._small._isClippedImage &
    margin-top 50px
    min-height 260px

.content
  flex-grow 1
  max-width 410px
  display flex
  justify-content center
  flex-direction column
  padding 20px 0 20px 150px
  z-index 2
  ._small &
    padding 10px 50px 20px 20px
  ._small._isClippedImage &
    margin-right 50px
    justify-content flex-end

.rubric
  position absolute
  top 20px
  left 25px
  z-index 2
  ._rubricWithBg &
    display inline-block
    border-radius 10px
    padding 5px 10px

.altHeadline
  margin-top 5px
  -webkit-line-clamp 4
  display -webkit-box
  -webkit-box-orient vertical
  overflow hidden

.read
  margin-top 20px
  width 100%

.imageSmallWrapper
  ._isClippedImage &
    transform translateX(50px)

.image
  clip-path polygon(22% 0, 100% 0, 100% 100%, 0% 100%)
  margin-left auto
  @media (prefers-reduced-motion: no-preference)
    transform scale(1) translateY(0)
    transition transform .4s
  ._small &
    clip-path polygon(0 0, 100% 0, 100% 100%, 0 85%)
    margin 0
  ._isClippedImage &
    position absolute
    bottom 0
    clip-path unset
    right 125px
  ._small._isClippedImage &
    right 0
    border-radius 0 0 8px 0
    overflow hidden

.waves
  position absolute
  z-index 1
  width 100%
  height 100%
  border-radius 16px
  bottom 0
  overflow hidden
  @media (prefers-reduced-motion: no-preference)
    &:before
      transition transform .4s
  ._isClippedImage &
  ._small &
    right 0
  ._small &
    height 100px
    width 260px

  &:before
    content ""
    position absolute
    right -30px
    bottom -30px
    width 720px
    height 100%
    transform rotate(20deg) translateX(30px)

.album
  position absolute
  right 30px
  bottom 40px
  z-index 2
  ._small &
    bottom 20px
    right 10px

.wrapper
  flex none

.header
  margin 0 auto
  display flex
  justify-content space-between
  align-items center
  box-sizing content-box
  max-width 1340px
  .stickyHeader &
    padding 0 50px 0 !important

.logoWrapper
  display flex
  flex 1 0 auto
  width 205px
  height 100%
  max-height 100%
  overflow hidden
  align-items center

.shapkaMenu
  flex 1 0 auto

.logoWrapper
.shapkaMenu
  transition opacity .15s linear
  ._searchFocused &
    opacity .2

.content
  max-width 1000px
  margin 0 auto

.card
  max-width 700px
  margin 0 auto

.stickyHeader
  position fixed
  left 50%
  width 100%
  top 0
  max-height 0
  overflow hidden
  transform translateX(-50%)
  z-index $baseZIndex
  a
  div
    font-size 14px !important
    line-height 16px !important
  @media (prefers-reduced-motion: no-preference)
    transition max-height .15s linear
  .logoWrapper
    margin-top 0
  &._visible
    max-height 100px
    overflow visible

.dropdownContent
  width 380px
  box-sizing border-box
  display flex
  align-content flex-start

.genres
  margin-right 80px

.groupTitle
  margin-bottom 20px

.sberPromo
  & + &
    margin-left 20px

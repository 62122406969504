.form
  flex 1 0 auto
  width 200px
  height 100%
  position relative
  display flex
  align-items center
  justify-content flex-end

.submit
  border none
  background transparent
  padding 10px
  position absolute
  right 0
  cursor pointer

.icon
  transform translateY(2px)

.input
  opacity 0
  width 0
  max-height 0
  padding 0
  border-radius 2px
  box-sizing border-box
  outline 0
  @media (prefers-reduced-motion: no-preference)
    transition max-height .1s linear, width .1s linear, padding .1s linear, opacity .1s linear
  &:focus
    opacity 1
    width 100%
    max-height 100%
    padding 10px 40px 10px 10px
  &::-moz-placeholder
    opacity 1
  &::-ms-clear
    display none
  &:invalid
    box-shadow none

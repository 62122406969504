.container
  margin 20px
  min-width 420px
  max-width 630px // 400px для 4-х карточек
  user-select none

  &._fullscreen
    max-width 630px

  :global(.mobile) &
    min-width 300px
    display flex

  &._error
    flex-direction column
    justify-content center
    text-align center
    flex 0 0 auto
.popup
  z-index 100
  box-sizing border-box
  padding 22px 32px 16px
  background rgba(0, 0, 0, 0.8)
  border-radius 6px
  text-align center
  display none
  position fixed
  right 20px

  &._opened
    display block

    :global(.desktop) &
      animation bounceInRight 1s

  @media (prefers-reduced-motion: no-preference)
    :global(.mobile) &
      animation bounceInUp 1s

  :global(.desktop) &
    bottom 18px
    width 260px

  :global(.mobile) &
    bottom 20px
    left 20px
    width auto

.emoji
  position absolute
  left 50%
  margin-bottom -14px
  top 0
  transform translate(-50%, -40%)

.text
  color #fff
  font-size 18px
  line-height 21px

.close
  position absolute
  top 4px
  right 4px
  padding 5px
  display flex
  cursor pointer

  :global(svg)
    fill #FFF
    opacity 0.3
    transition .2s opacity

  &:hover
    :global(svg)
      opacity 0.5

@keyframes bounceInRight
  from,
  60%,
  75%,
  90%,
  to
    animation-timing-function cubic-bezier(0.215, 0.61, 0.355, 1)

  from
    opacity 0
    transform translate3d(3000px, 0, 0) scaleX(3)

  60%
    opacity 1
    transform translate3d(-25px, 0, 0) scaleX(1)

  75%
    transform translate3d(10px, 0, 0) scaleX(0.98)

  90%
    transform translate3d(-5px, 0, 0) scaleX(0.995)

  to
    transform translate3d(0, 0, 0)

@keyframes bounceInUp
  from,
  60%,
  75%,
  90%,
  to
    animation-timing-function cubic-bezier(0.215, 0.61, 0.355, 1)

  from
    opacity 0
    transform translate3d(0, 3000px, 0) scaleY(5)

  60%
    opacity 1
    transform translate3d(0, -20px, 0) scaleY(0.9)

  75%
    transform translate3d(0, 10px, 0) scaleY(0.95)

  90%
    transform translate3d(0, -5px, 0) scaleY(0.985)

  to
    transform translate3d(0, 0, 0)

:global
  .sberbank-partners
    div
      padding-left 68px
      &:before
        border 1px solid #eeeeee

  .sberbank-features
    div
      padding-left 64px

  .sberbank-partners
  .sberbank-features
    display grid
    grid-template-columns repeat(2, 1fr)
    gap 20px

    *
      padding 0
      margin 0

    div
      position relative
      display block
      color #4d4d4d
      text-decoration none
      min-height 52px

      &:before
        content ''
        border-radius 12px
        position absolute
        left 0
        width 50px
        height 50px
        background-size contain
        background-repeat no-repeat
        background-image url('./images/sberBank.png')

      &._eApteka:before
        background-image url('./images/eApteka.png')

      &._okko:before
        background-image url('./images/okko.png')

      &._sberBank:before
        background-image url('./images/sberBank.png')

      &._samokat:before
        background-image url('./images/samokat.png')

      &._zvuk:before
        background-image url('./images/zvuk.png')

      &._sberDisk:before
        background-image url('./images/sberDisk.png')

      &._sberEnsurance:before
        background-image url('./images/sberEnsurance.png')

      &._sberHealth:before
        background-image url('./images/sberHealth.png')

      &._sberLogistics:before
        background-image url('./images/sberLogistics.png')

      &._sberMarket:before
        background-image url('./images/kuper.png')

      &._kuper:before
        background-image url('./images/kuper.png')

      &._sberMegaMarket:before
        background-image url('./images/sberMegaMarket.png')

      &._sberMobile:before
        background-image url('./images/sberMobile.png')

      &._sberHeadset:before
        background-image url('./images/headset.png')

      &._sberSound:before
        background-image url('./images/sound.png')

      &._sberHifi:before
        background-image url('./images/hifi.png')

      &._sberWave:before
        background-image url('./images/wave.png')

      &._sberLibrary:before
        background-image url('./images/library.png')

      &._sberTuner:before
        background-image url('./images/tuner.png')

      &._sberPodcasts:before
        background-image url('./images/podcasts.png')

      &._sberAudiobooks:before
        background-image url('./images/audiobooks.png')

      &._sberGigamix:before
        background-image url('./images/gigamix.png')

      &._sberApplication:before
        background-image url('./images/application.png')

      &._sberOffline:before
        background-image url('./images/offline.png')

      &._sberTexts:before
        background-image url('./images/texts.png')

      &._sberTouch:before
        background-image url('./images/touch.png')

      &._sberNew:before
        background-image url('./images/new.png')

      &._sberSpasibo:before
        background-image url('./images/sberSpasibo.png')

      &._otello:before
        background-image url('./images/otello.png')

      &._sberSpasiboOriginal:before
        background-image url('./images/sberSpasiboOriginal.png')

    p
      font 400 15px/18px Roboto, sans-serif
      margin-bottom 5px

    span
      font 400 11px/14px Roboto, sans-serif
      color #949494

  .mobile .sberbank-partners
  .mobile .sberbank-features
    grid-template-columns 1fr

  .snippet-button
    flex 0
    white-space nowrap
    border none
    outline none
    background none
    padding 0
    margin 0
    width auto
    overflow visible
    cursor pointer
    transform scale(1)
    transition .35s transform

    &:hover
      transform scale(1.04)

    a
      color #FFF
      background linear-gradient(90deg, #CD3DFA 0%, #6775FC 100%)
      display inline-block
      padding 8px 22px 7px
      text-decoration none
      text-transform uppercase
      font-size 20px
      line-height 24px
      border-radius 999em
      box-sizing border-box

$imageRadius = 15px
.caption 
  :global(.desktop) &
    margin 15px 20px 0 0
  :global(.mobile) &
    margin 10px 20px 0

.coverImage
  position relative

.image
  display flex
  justify-content center

  :global(.desktop) &
    border-radius $imageRadius
    overflow hidden

.info
  :global(.desktop) &
    margin-left $imageRadius
  :global(.mobile) &
    margin-top 10px
    padding-bottom 10px

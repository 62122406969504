.galleryContainer
  width 1000px
  background #000000
  margin 0 auto
  border-radius 16px
  overflow hidden

.galleryWrapper
  width 780px
  padding 20px 110px
  margin 0 auto
  overflow hidden
  background radial-gradient(276.63% 72.89% at 50% 47.07%, #31255E 0%, rgba(0, 0, 0, 0) 100%)

.readMore
  position relative
  padding 20px 115px 40px

  &:before
    content ''
    position absolute
    z-index -1
    width 100%
    height 100%
    transform rotate(-2.3deg) translate3d(-115px, -20px, 0)

  :global(.mobile) &
    padding 20px

    &:before
      transform rotate(-2.3deg) translate3d(-20px, -20px, 0)


.title
  border-radius 30px
  width 125px
  margin -40px calc(50% - 80px) 20px
  padding 10px 15px
  background-color #FFF
.container
  :global(.desktop) &
    position relative
    padding 32px 20px
    margin-top 60px

    &:before
      content ''
      position absolute
      inset 0
      border-radius 16px
      background-image linear-gradient(125.37deg, #833FBC 5.11%, #0187CE 87.09%)

    &:after
      content ''
      position absolute
      inset 0
      top -70px
      background 5% 0 / contain url('./images/headphones.png') no-repeat

  :global(.mobile) &
    padding 16px 20px
    background-image url('./images/headphones.png'), linear-gradient(125.37deg, #833FBC 5.11%, #0187CE 87.09%)
    background-size contain
    background-repeat no-repeat

.content
  color #fff
  text-align center

  :global(.desktop) &
    margin-left 40%
    position relative
    z-index 1

  :global(.mobile) &
    margin-left 25%

.headline
  margin-bottom 9px
  font-size 32px
  line-height 38px

  :global(.mobile) &
    font-size 24px
    line-height 28px
    margin-bottom 5px

.subtitle
  font-size 14px
  line-height 21px
  margin-bottom 23px

  :global(.mobile) &
    margin-bottom 10px

.button
  display inline-flex
  flex-direction column
  align-items center

  :global(.desktop)
    padding 10px 0 26px

.buttonLink
  display block

  :global(.desktop) &
    @media (prefers-reduced-motion: no-preference)
      transform scale(1) translateY(0)
      transition transform .4s
      &:hover
        transform scale(1.08) translateY(-3px)

.buttonText
  font-size 18px
  line-height 21px
  letter-spacing 0.2px
  color #fff

  :global(.mobile) &
    font-size 13px
    line-height 16px
